import React from 'react';
import PropTypes from 'prop-types';

import styles from './NumberInput.module.css';

const NumberInput = ({ label, onChange, value }) => (
    <>
        <span className={styles.label}>{ label }</span>
        <input className={styles.input} type="number" onChange={onChange} value={value}></input>
    </>
);

NumberInput.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
};

export default NumberInput;
