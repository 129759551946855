import React, { useState } from 'react';

import Box, { COLORS } from './Box';
import NumberInput from './NumberInput';
import RadioInput from './RadioInput';

import styles from './App.module.css';

const OPERATIONS = {
  ADDITION: {
    key: 'addition',
    label: 'Addition',
  },
  MULTIPLICATION: {
    key: 'multiplication',
    label: 'Multiplication',
  },
};

const getResults = (inputNumbers, operation) => {
  switch (operation) {
    case (OPERATIONS.ADDITION.key):
      return inputNumbers.first + inputNumbers.second + inputNumbers.third;
    case (OPERATIONS.MULTIPLICATION.key):
      return inputNumbers.first * inputNumbers.second * inputNumbers.third;
    default:
      throw Error('invalid operation');
  }
}

const App = () => {
  const [inputNumbers, setInputNumbers] = useState({first: 0, second: 0, third: 0});
  const [operation, setOperation] = useState(OPERATIONS.ADDITION.key);

  return (
    <div className={styles.app}>
      <Box color={COLORS.BLUE}>
        <NumberInput 
          label={"Value 1:"}
          value={inputNumbers.first}
          onChange={e => setInputNumbers({ ...inputNumbers, first: parseInt(e.target.value, 10) || 0})}
        />
      </Box>
      <Box color={COLORS.BLUE}>
        <NumberInput 
          label={"Value 2:"} 
          value={inputNumbers.second}
          onChange={e => setInputNumbers({ ...inputNumbers, second: parseInt(e.target.value, 10) || 0})}
        />
      </Box>
      <Box color={COLORS.BLUE}>
        <NumberInput 
          label={"Value 3:"}
          value={inputNumbers.third}
          onChange={e => setInputNumbers({ ...inputNumbers, third: parseInt(e.target.value, 10) || 0})}
        />
      </Box>
      <Box color={COLORS.RED}>
        <div className={styles.resultsContainer}>
          <RadioInput 
            options={Object.values(OPERATIONS)} 
            name={'operations'} 
            checked={operation} 
            onChange={e => setOperation(e.target.value)}
          />
          <div className={styles.results}>
            <span className={styles.resultsLabel}>
              Result: 
            </span>
            {getResults(inputNumbers, operation)}
          </div>
        </div>
      </Box>
    </div>
  );
}

export default App;
