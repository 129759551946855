import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({ options, checked, name, onChange }) => (
    <>
        { options.map(o => (
            <div key={o.key}>
                <input 
                    type="radio" 
                    id={o.key} 
                    name={name} 
                    value={o.key} 
                    checked={checked === o.key} 
                    onChange={onChange}
                ></input>
                <label htmlFor={o.key}>{o.label}</label>
            </div>
        )) }
    </>
);

const optionType = {
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

RadioInput.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape(optionType)),
    checked: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default RadioInput;