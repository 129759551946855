import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Box.module.css';


export const COLORS = {
    BLUE: styles.colorBlue,
    RED: styles.colorRed,
};

const Box = ({ color, children }) => (
    <div className={cx(styles.box, color)}>
        {children}
    </div>
);

Box.propTypes = {
    color: PropTypes.oneOf(Object.values(COLORS)).isRequired,
    children: PropTypes.node.isRequired,
};

export default Box;